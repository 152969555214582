import React from "react"

export const BackgroundLoop = props => {
  return (
    <svg
      width="159"
      height="160"
      viewBox="0 0 159 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0297 52.4214C48.3328 36.3078 62.9242 29.1566 78.0415 27.6508C93.1859 26.1423 108.834 30.3074 120.108 36.8164C137.043 46.5939 145.239 58.9978 147.678 64.8783L142.679 64.605L150.286 71.2074L152.2 61.3184L148.991 64.1298C146.321 57.8393 137.879 45.3443 120.858 35.5173C109.34 28.8673 93.38 24.6155 77.8928 26.1582C62.3785 27.7035 47.3145 35.0716 37.7306 51.6714C35.9785 54.7062 34.6332 57.6343 33.6404 60.4492C26.2177 63.5491 19.4607 69.0731 15.1533 76.5338C7.33752 90.0711 9.44099 104.053 11.5027 109.421L12.903 108.884C10.9658 103.839 8.91077 90.3461 16.4523 77.2838C20.3495 70.5337 26.3499 65.4222 33.0144 62.361C30.3911 71.0455 31.1273 78.597 33.5313 84.8154C36.8599 93.4257 43.3544 99.4021 48.3456 102.284L48.357 102.29C51.05 103.783 55.5679 105.093 60.2804 104.563C65.0327 104.029 69.9727 101.622 73.3856 95.7103C80.2073 83.8947 75.2713 69.2617 61.0636 61.0589C53.8653 56.903 44.7733 56.6314 36.3214 59.4444C36.049 59.535 35.7772 59.6289 35.506 59.726C36.413 57.375 37.5761 54.9391 39.0297 52.4214ZM34.9304 84.2746C32.5443 78.1024 31.8521 70.4799 34.8368 61.5815C35.4851 61.3243 36.1383 61.0862 36.7951 60.8676C44.9393 58.1571 53.5685 58.4637 60.3136 62.3579C73.9926 70.2556 78.3929 84.0374 72.0866 94.9603C68.9355 100.418 64.4409 102.586 60.113 103.072C55.7487 103.563 51.544 102.34 49.0898 100.981C44.3392 98.2369 38.1125 92.506 34.9304 84.2746Z"
        fill="#663399"
      />
    </svg>
  )
}
